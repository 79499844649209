<template lang="">
<fullscreen v-model="fullscreen">

    <!-- <Transition name="fade" mode="out-in" v-on:after-enter="enter" v-on:after-leave="leave"> -->
    <Transition name="fade" mode="out-in">

        <component :is="activeComponent" :score="final_score"></component>
    </Transition>

</fullscreen>

<!-- <v-div  v-show="!$vuetify.breakpoint.mobile" >
        <v-row class="align-center d-flex">
            <v-col lg="4" sm="8"  class="mx-auto">
                <v-row class="my-auto">
                    <v-col class="mx-auto" style="padding: 0px;">
                        <div :style="image" class="imgbox_2"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
</v-div> -->
</template>

<script>
import {
    LIFFID
} from '@/constants'
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'
import s_pdpa from '@/views/public_pages/azq_component_scene/s_pdpa.vue'
import s_1 from '@/views/public_pages/azq_component_scene/s_1.vue'
import s_2 from '@/views/public_pages/azq_component_scene/s_2.vue'
import s_3 from '@/views/public_pages/azq_component_scene/s_3.vue'
import s_4 from '@/views/public_pages/azq_component_scene/s_4.vue'
import s_5 from '@/views/public_pages/azq_component_scene/s_5.vue'
import s_5_6 from '@/views/public_pages/azq_component_scene/s_5_6.vue'
import s_6 from '@/views/public_pages/azq_component_scene/s_6.vue'
import s_6_5 from '@/views/public_pages/azq_component_scene/s_6_5.vue'
import s_7 from '@/views/public_pages/azq_component_scene/s_7.vue'
import s_8 from '@/views/public_pages/azq_component_scene/s_8.vue'
import s_9 from '@/views/public_pages/azq_component_scene/s_9.vue'
import s_10 from '@/views/public_pages/azq_component_scene/s_10.vue'
import s_11 from '@/views/public_pages/azq_component_scene/s_11.vue'
import s_12 from '@/views/public_pages/azq_component_scene/s_12.vue'
import s_13 from '@/views/public_pages/azq_component_scene/s_13.vue'
import s_14 from '@/views/public_pages/azq_component_scene/s_14.vue'
import s_15 from '@/views/public_pages/azq_component_scene/s_15.vue'
import s_16 from '@/views/public_pages/azq_component_scene/s_16.vue'
import s_17 from '@/views/public_pages/azq_component_scene/s_17.vue'
import s_18 from '@/views/public_pages/azq_component_scene/s_18.vue'
import s_19 from '@/views/public_pages/azq_component_scene/s_19.vue'
import s_20 from '@/views/public_pages/azq_component_scene/s_20.vue'
import s_21 from '@/views/public_pages/azq_component_scene/s_21.vue'
import s_22 from '@/views/public_pages/azq_component_scene/s_22.vue'
import s_23 from '@/views/public_pages/azq_component_scene/s_23.vue'
import s_24 from '@/views/public_pages/azq_component_scene/s_24.vue'
import s_25 from '@/views/public_pages/azq_component_scene/s_25.vue'
import s_26 from '@/views/public_pages/azq_component_scene/s_26.vue'
import s_27 from '@/views/public_pages/azq_component_scene/s_27.vue'
import s_score_1 from '@/views/public_pages/azq_component_scene/s_score_1.vue'
import s_score_2 from '@/views/public_pages/azq_component_scene/s_score_2.vue'
import s_table from '@/views/public_pages/azq_component_scene/s_table.vue'
import s_30 from '@/views/public_pages/azq_component_scene/s_30.vue'
// import score_1 from '@/views/public_pages/azq_component_scene/s_27.vue'

Vue.use(VueFullscreen)
export default {
    components: {
        s_pdpa,
        s_1,
        s_2,
        s_3,
        s_4,
        s_5,
        s_5_6,
        s_6,
        s_6_5,
        s_7,
        s_8,
        s_9,
        s_10,
        s_11,
        s_12,
        s_13,
        s_14,
        s_15,
        s_16,
        s_17,
        s_18,
        s_19,
        s_20,
        s_21,
        s_22,
        s_23,
        s_24,
        s_25,
        s_26,
        s_27,
        s_score_1,
        s_score_2,
        s_table,
        s_30
    },
    data() {
        return {
            current_scene_w: 0,
            windowSize: {
                x: 0,
                y: 0,
            },
            show_close: false,
            dialog: false,
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_blue2.jpg')})`
            },
            bg_sound: new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3')),
            final_score: 0,
            hidden: false,
            azq_ans: {},
            activeComponent: 's_pdpa',
            fullscreen: false,
            show: true,
            // image: {backgroundImage: `url(${require('@/assets/img/checkmong/azq/1658798766809.gif')})`}
        };
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    mounted() {
        this.onResize()
        // this.setBgSound()
        // //console.log(`the component is now mounted.`)
        // this.call()
        // this.toggleApi()
        // this.playSound()
        this.$store.commit('setAzq_type', {
                    'azq_type': 'azq_provider'
                });
    },
    // updated(){
    //     //console.log('On update')
    //     this.azq_ans = this.$store.state.azq_ans
    // },
    watch: {

        show_close(val) {
            //console.log('show_close------->', val)
        },
        '$store.state.azq_ans.stop_testing'(val){
            //console.log('stop_testing------->', val)
            if(val == 1){
                this.stopTesting()
            }
        }
        ,
        '$store.state.azq_ans.bg_sound'(val){
            //console.log('bg_sound------->', val)
            if(val == 1){
                this.stopTesting()
            }
        }
        ,
        '$store.state.azq_ans.current_s'(current_s) {
            //console.log(current_s)
            if (current_s == 's_pdpa') {
                this.activeComponent = 's_1'
                this.toggleApi()
                // this.play(this.bg_sound)
                this.setBgSound()
            } else if (current_s == 's_1') {
                this.activeComponent = 's_2'
            } else if (current_s == 's_2') {
                this.activeComponent = 's_3'
            } else if (current_s == 's_3') {
                this.activeComponent = 's_4'
            } else if (current_s == 's_4') {
                this.activeComponent = 's_5'
            } else if (current_s == 's_5') {
                this.activeComponent = 's_6'
            } else if (current_s == 's_5_6') {
                this.activeComponent = 's_6'
            } else if (current_s == 's_6') {
                this.activeComponent = 's_6_5'
            } else if (current_s == 's_6_5') {
                this.activeComponent = 's_7'
            }else if (current_s == 's_7') {
                this.activeComponent = 's_8'
            } else if (current_s == 's_8') {
                this.activeComponent = 's_9'
            } else if (current_s == 's_9') {
                this.activeComponent = 's_10'
            } else if (current_s == 's_10') {
                this.activeComponent = 's_11'
            } else if (current_s == 's_11') {
                this.activeComponent = 's_12'
            } else if (current_s == 's_12') {
                this.activeComponent = 's_13'
            } else if (current_s == 's_13') {
                this.activeComponent = 's_14'
            } else if (current_s == 's_14') {
                this.activeComponent = 's_15'
            } else if (current_s == 's_15') {
                this.activeComponent = 's_16'
            } else if (current_s == 's_16') {
                this.activeComponent = 's_17'
            } else if (current_s == 's_17') {
                this.activeComponent = 's_18'
            } else if (current_s == 's_18') {
                this.activeComponent = 's_19'
            } else if (current_s == 's_19') {
                this.activeComponent = 's_20'
            } else if (current_s == 's_20') {
                this.activeComponent = 's_21'
            } else if (current_s == 's_21') {
                this.activeComponent = 's_22'
            } else if (current_s == 's_22') {
                this.activeComponent = 's_23'
            } else if (current_s == 's_23') {
                this.activeComponent = 's_24'
            } else if (current_s == 's_24') {
                this.activeComponent = 's_25'
            } else if (current_s == 's_25') {
                this.activeComponent = 's_26'
            } else if (current_s == 's_26') {
                this.activeComponent = 's_27'
            } else if (current_s == 's_27') {
                const sum = this.cal_ans()
                // //console.log(sum)
                this.final_score = sum
                this.activeComponent = 's_score_1'
            } else if (current_s == 's_score_1') {
                this.activeComponent = 's_score_2'
            } else if (current_s == 's_score_2') {
                this.activeComponent = 's_table'
            } else if (current_s == 's_table') {
                this.activeComponent = 's_30'
            } else if (current_s == 's_30') {
                this.pause(this.bg_sound)
                this.$fullscreen = false
            } else if (current_s == 'pdpa_cancle') {
                this.pause(this.bg_sound)
                const azq_ans_temp = this.$store.state.azq_ans

                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'

                });
                this.$router.push('/home')

            }
        }
    },
    methods: {
        setBgSound(){
                const azq_ans_temp = this.$store.state.azq_ans
                this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'bg_sound': this.bg_sound,
                'full_name':this.$store.state.qr_patient_info.fullname,
                'phone_number':this.$store.state.qr_patient_info.phone_number,
                'age':this.$store.state.qr_patient_info.age,
                'user_id':this.$store.state.qr_patient_info.user_id

            });

            // this.azq_ans_temp = this.$store.state.azq_ans
            //     this.azq_ans_temp.s_pdpa = this.pdpa_accept
            //     this.azq_ans_temp.current_s = 's_pdpa'
            //     //console.log(this.azq_ans_temp)
            //     // //console.log(current_ans)
            //     // this.play(this.bg_sound)
            //     this.$store.commit('setAzqAns', {
            //         ...this.azq_ans_temp
            //     });
            this.play(this.$store.state.azq_ans.bg_sound)

        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:',this.windowSize)
            this.current_scene_w = this.windowSize.y
            //console.log('current_scene_w:',this.current_scene_w*0.45)

        },
        enter() {
            //console.log('start')
            this.show_close = true
        },
        leave() {
            //console.log('left')
            this.show_close = false
        },
        onImgLoad() {
            // this.isLoaded = true
            //console.log('-------', this.isLoaded)
        },
        stopTesting() {
            const azq_ans_temp = this.$store.state.azq_ans

            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'stop_testing': '1'

            });

            this.pause(this.bg_sound)
            // this.toggleApi()
            this.$fullscreen = false
            this.dialog = false
            this.$router.push('/home')

        },
        play(audio) {
            audio.isPlaying = true;
            if (typeof audio.loop == 'boolean') {
                audio.loop = true;
            } else {
                audio.addEventListener('ended', function () {
                    this.currentTime = 0;
                    this.play();
                }, false);
            }
            audio.play();
        },

        pause(audio) {
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        },
        cal_ans() {

            // 1-1 
            // 2-1 
            // 3-2 
            // 4-1 
            // 5-1 
            // 6-1 
            // 7-2 
            // 8-1 
            // 9-1 
            // 10-1 
            // 11-2 
            // 12-1
            // 13-1
            // 14-1
            // 15-1
            // 16-1
            // 17-2
            // 18-1
            // 19-1
            // 20-2
            // 21-2

            const azq_ans_temp = this.$store.state.azq_ans
            var sum = 0
            if (azq_ans_temp.ans_1) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_2) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_3) {
                sum = sum + 2
            }
            if (azq_ans_temp.ans_4) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_5) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_6) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_7) {
                sum = sum + 2
            }
            if (azq_ans_temp.ans_8) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_9) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_10) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_11) {
                sum = sum + 2
            }
            if (azq_ans_temp.ans_12) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_13) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_14) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_15) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_16) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_17) {
                sum = sum + 2
            }
            if (azq_ans_temp.ans_18) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_19) {
                sum = sum + 1
            }
            if (azq_ans_temp.ans_20) {
                sum = sum + 2
            }
            if (azq_ans_temp.ans_21) {
                sum = sum + 2
            }
            //console.log(sum)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'point': sum

            });
            return sum

        },
        playSound() {
            var audio = new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3'));
            audio.loop = true;
            audio.volume = 1;
            audio.play();
        },
        call() {
            //console.log("call")
        },
        toggleApi() {
            //console.log('77777toggleApi')
            this.$fullscreen.toggle()
            this.hidden = !this.hidden
        },
    },
};
</script>

<style >
body {}

.imgbox {
    background-size: cover;
    background-repeat: no-repeat;
    height: 800px;
    width: auto;
    /*
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    align-self: center;*/
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.ans_s_mobile {

    position: absolute ;
    z-index: 999 ;
    margin-top: 51% ;
    margin-left: 30% ;
    width: 200px ;

}

.ans_s_desktop {
    position: absolute !important;
    z-index: 999 !important;
    margin-top: 0px !important;
    margin-left: -88px !important;
    width: 200px !important;
    left: 50% !important;
    top: 24% !important;
}

.s_mobile {
    right: 0.2rem !important;
    margin-top: -0.2rem;
}

.s_desktop {
    margin-left: -0.2rem;
    margin-top: -0.4rem;

}

.s_next_mobile {
    margin-right: -0.7rem;
    margin-top: 2.4rem;
}

.s_next_desktop {
    margin-left: 0rem;
    margin-top: 2.2rem;
}

.s_voice_mobile {

    margin-top: -0.2rem;
    margin-left: -0.9rem !important;
}

.s_voice_desktop {
    margin-left: 0rem ;
    margin-top: -0.3rem;
}
</style>
